<template>
    <div id="AdvancedCourseVideos">
        <ul class="video-list">
            <li v-for="(item, index) in videoList" :key="item._id">
                <h3 class="f20 f18-mb" @click="openVideo(index)">
                    {{ item.name }}
                    <ElIcon v-if="serverRole !== 'admin' && item.isWatched"
                        ><SuccessFilled
                    /></ElIcon>
                </h3>
            </li>
        </ul>

        <article
            id="advanced-video-article"
            v-if="serverRole === 'user' && subCategory === '神学科・第五段階'"
        >
            <h3 class="f16 f14-mb bold">【次の段階に進むには】</h3>
            <p class="f14 f12-mb">さらに上級へ進みたい方は、</p>
            <ul class="disc-list-ul f14 f12-mb">
                <li class="disc-list-li">「おつとめ親交式の理」の修得</li>
                <li class="disc-list-li">
                    「親理総合理学修の理」への真参加（6回以上）
                </li>
                <li class="disc-list-li">「真御子（神の子）宣誓」</li>
            </ul>
            <p class="f14 f12-mb">
                を行って下さい。
                <br />
                まずは<router-link
                    :to="{ name: 'CourseForm' }"
                    class="text-link-style"
                    >「お問い合わせ」フォーム</router-link
                >より「おつとめ親交式 純御修行の理」をお申し込みください。
            </p>
        </article>

        <div class="button-wrap">
            <router-link :to="backRouteLink" class="button-style"
                >戻る</router-link
            >
            <router-link
                :to="nextRouteLink"
                v-if="
                    subCategory !== '神学科・第五段階' &&
                    (serverRole === 'admin' || showNextRoute)
                "
                class="button-style"
                >次の段階の親理を頂く</router-link
            >
            <span
                v-if="
                    serverRole !== 'admin' &&
                    subCategory === '神学科・第五段階' &&
                    showNextRoute
                "
                @click="finishAllCourse"
                class="button-style"
                >真御子宣誓</span
            >
        </div>

        <ElDialog
            v-if="videoList.length"
            v-model="showVideo"
            :title="videoList[activatedIdx].name"
            :before-close="handleClose"
        >
            <video
                ref="video"
                controls
                controlsList="nodownload"
                @ended="finishVideo(videoList[activatedIdx])"
            >
                <source
                    :src="
                        isPC
                            ? videoList[activatedIdx].video1280Url
                            : videoList[activatedIdx].video720Url
                    "
                    type="video/mp4"
                />
            </video>
        </ElDialog>
    </div>
</template>
<script>
export default {
    name: 'AdvancedCourseVideos',
}
</script>
<script setup>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { watchCourse, finishAllCourse } from '@/service/api'
import { ElMessageBox } from 'element-plus'
import { SuccessFilled } from '@element-plus/icons-vue'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
const store = useStore()
const route = useRoute()
const router = useRouter()
const serverRole = ref(store.getters.getServerRole)
onMounted(async () => {
    if (serverRole.value === 'admin') return
    showDonateDialog()
})

function showDonateDialog() {
    if (
        !store.getters.getDonateStatus[subCategory.value] &&
        !store.getters.getDonateDialogStatus[subCategory.value]
    ) {
        if (!store.getters.getDonateDialogStatus[subCategory.value]) {
            store.commit('updateDonateDialogStatus', subCategory.value)
        }
        setTimeout(() => {
            ElMessageBox.confirm(
                '次の段階の「貴き親理のお授け」<br/>真拝命拝霊拝受させて頂くにあたり、 <br/>『大親神大生命』に対し奉り <br/>「おつなぎ徳積みの理」を行わせて頂きましょう。 ',
                'おつなぎ徳積みの理',
                {
                    confirmButtonText: '今すぐ行わせて頂く',
                    cancelButtonText: 'あとで行う',
                    center: true,
                    dangerouslyUseHTMLString: true,
                }
            ).then(() => {
                cookies.set(
                    'backRoutePath',
                    `/member/dashboard/course/advanced/videos/${subCategory.value}`,
                    60 * 5
                )
                cookies.set('donateCourse', subCategory.value, 60 * 5)
                router.push({ name: 'DonateProcessStripe' })
            })
        }, 1000)
    }
}

const subCategory = ref(route.params.subCategory)
const videoList = ref(store.getters.getTheologyVideos(subCategory.value))
const subCategoryCompleted = ref(
    store.getters.getTheologyStatus(subCategory.value)
)
onBeforeRouteUpdate((to) => {
    videoList.value = store.getters.getTheologyVideos(to.params.subCategory)
    subCategoryCompleted.value = store.getters.getTheologyStatus(
        to.params.subCategory
    )
    subCategory.value = to.params.subCategory
    showDonateDialog()
})
const isPC = store.getters.isPC

const showVideo = ref(false)
const activatedIdx = ref(0)
const video = ref(null)

const backRouteLink = computed(() => {
    if (serverRole.value === 'user')
        return {
            name: 'AdvancedCourseList',
        }
    else if (serverRole.value === 'admin')
        return {
            name: 'AdminAdvancedCourseList',
        }
    else return { name: 'Home' }
})

const showNextRoute = computed(() => {
    if (subCategory.value === '神学科・第一段階') return true
    else if (subCategoryCompleted.value) return true
    else return false
})
const nextRouteLink = computed(() => {
    if (subCategory.value === '神学科・第五段階') {
        if (serverRole.value === 'user')
            return {
                name: 'AdvancedCourseList',
            }
        else if (serverRole.value === 'admin')
            return {
                name: 'AdminAdvancedCourseList',
            }
        else return { name: 'Home' }
    }
    let nextSubCategory = store.getters.getNextSubcategory(subCategory.value)
    if (serverRole.value === 'user')
        return {
            name: 'AdvancedCourseVideos',
            params: { subCategory: nextSubCategory },
        }
    else if (serverRole.value === 'admin')
        return {
            name: 'AdminAdvancedCourseVideos',
            params: { subCategory: nextSubCategory },
        }
    else return { name: 'Home' }
})

function openVideo(idx) {
    showVideo.value = true
    activatedIdx.value = idx
    if (video.value) {
        video.value.load()
        // video.value.setAttribute(
        //     'poster',
        //     videoList.value[activatedIdx].thumbnailUrl
        // )
    }
}
async function finishVideo(item) {
    console.log(item)
    await watchCourse(item)
    subCategoryCompleted.value = store.getters.getTheologyStatus(
        subCategory.value
    )
    console.log(subCategoryCompleted.value)
}
function handleClose(done) {
    video.value.pause()
    done()
}
</script>
<style lang="scss" scoped>
.video-list {
    list-style-type: decimal-leading-zero;
    padding-left: 2em;
    li {
        list-style-type: decimal-leading-zero;
    }
    h3 {
        display: inline;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            color: $gray6;
        }
    }
}
.button-wrap {
    display: flex;
    padding: 60px 0;
    gap: 20px;
    .button-style {
        padding: 3px 30px;
        width: max-content;
    }
}
/deep/ .el-dialog {
    video {
        width: 100%;
    }
}

article {
    padding: 30px 0;
    h3 {
        margin: 2em 0 0.5em;
    }
}
</style>
